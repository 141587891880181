import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import trapImageApi from '../../apis/TrapImageApi';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { Page } from '../../models/Elements';
import { TrapImage } from '../../models/Entities';
import alertService from '../../services/AlertService';
import paginationService from '../../services/TableService';

/**
 * Returns the admin trap images page.
 * @returns the admin trap images page.
 */

const AdminTrapImagesPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [trapImagesPage, setTrapImagesPage] = useState<Page<TrapImage>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | string>();

    //list traps
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const trapImagesPage = await trapImageApi.list(
                    page,
                    paginationService.pageSize,
                    sortField,
                    sortOrder,
                    undefined,
                    undefined,
                    undefined,
                    filter.searchText
                );
                setTrapImagesPage(trapImagesPage);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** METHODS ***/

    const download = async (trapImage: TrapImage) => {
        try {
            setLoading(`downloading-${trapImage.id}`);
            if (trapImage.url) {
                const image = await trapImageApi.download(trapImage.url);
                FileSaver.saveAs(image, intl.formatMessage({ id: `${trapImage.id}.png` }));
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
        setPage(0);
    };

    /*** VISUAL ***/

    const items = trapImagesPage ? trapImagesPage.content : [];
    const columns: ColumnsType<TrapImage> = [
        {
            title: <FormattedMessage id="trapImage.id" />,
            dataIndex: 'id',
            key: 'id',
            width: 35,
            align: 'right',
            fixed: 'left',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: string | undefined, trapImage: TrapImage) => <Link to={`/admin/trap-images/${trapImage.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="trapImage.trapUuid" />,
            dataIndex: 'trapUuid',
            key: 'trapUuid',
            fixed: 'left',
            width: 80,
            sorter: true,
            render: (value: string | undefined, trapImage: TrapImage) => <Link to={`/admin/trap-images/${trapImage.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="trapImage.company" />,
            dataIndex: 'companyName',
            key: 'companyName',
            width: 95,
            fixed: 'left',
            sorter: true,
            render: (value: string | undefined, trapImage: TrapImage) => <Link to={`/admin/trap-images/${trapImage.id}`}>{trapImage.company?.name}</Link>
        },
        {
            title: <FormattedMessage id="trapImage.plague" />,
            dataIndex: 'plague',
            key: 'plague',
            fixed: 'left',
            width: 80,
            render: (value: string | undefined, trapImage: TrapImage) => (
                <Link to={`/admin/trap-images/${trapImage.id}`}>
                    <ConstantLabel value={value} prefix="plague.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.date" />,
            dataIndex: 'date',
            key: 'date',
            width: 100,
            align: 'center',
            fixed: 'left',
            sorter: true,
            render: (value: number | undefined, trapImage: TrapImage) => (
                <Space>
                    <Link to={`/admin/trap-images/${trapImage.id}`}>
                        <FormattedDate value={value as any} year="numeric" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" />
                    </Link>
                    <Tooltip title={<FormattedMessage id="button.download" />}>
                        <Button
                            icon={<CloudDownloadOutlined />}
                            onClick={() => download(trapImage)}
                            loading={loading === `downloading-${trapImage.id}`}
                            type="link"
                        />
                    </Tooltip>
                </Space>
            )
        },
        {
            title: <FormattedMessage id="trapImage.insectsCount" />,
            dataIndex: 'insectsCount',
            key: 'insectsCount',
            width: 50,
            sorter: true,
            align: 'right',
            render: (value: string | undefined, trapImage: TrapImage) => (
                <Link to={`/admin/trap-images/${trapImage.id}`}>
                    <strong>{value}</strong>
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.insectsCountAi" />,
            dataIndex: 'insectsCountAi',
            key: 'insectsCountAi',
            width: 55,
            sorter: true,
            align: 'right',
            render: (value: string | undefined, trapImage: TrapImage) => <Link to={`/admin/trap-images/${trapImage.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="trapImage.temperature" />,
            dataIndex: 'temperature',
            key: 'temperature',
            width: 60,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, trapImage: TrapImage) =>
                trapImage.temperature && (
                    <Link to={`/admin/trap-images/${trapImage.id}`}>
                        <FormattedNumber
                            value={trapImage.temperature}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="celsius"
                            unitDisplay="short"
                            style={'unit' as any}
                        />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.humidity" />,
            dataIndex: 'humidity',
            key: 'humidity',
            width: 55,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, trapImage: TrapImage) =>
                trapImage.humidity && (
                    <Link to={`/admin/trap-images/${trapImage.id}`}>
                        <FormattedNumber
                            value={trapImage.humidity / 100}
                            minimumFractionDigits={1}
                            maximumFractionDigits={1}
                            unit="percent"
                            unitDisplay="short"
                            style={'percent' as any}
                        />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.batteryVoltage" />,
            dataIndex: 'batteryVoltage',
            key: 'batteryVoltage',
            width: 40,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, trapImage: TrapImage) =>
                trapImage.batteryVoltage && (
                    <Link to={`/admin/trap-images/${trapImage.id}`}>
                        <FormattedNumber value={trapImage.batteryVoltage} minimumFractionDigits={2} maximumFractionDigits={2} /> V
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.signal" />,
            dataIndex: 'signal',
            key: 'signal',
            width: 40,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, trapImage: TrapImage) =>
                trapImage.signal && (
                    <Link to={`/admin/trap-images/${trapImage.id}`}>
                        <FormattedNumber value={trapImage.signal} minimumFractionDigits={2} maximumFractionDigits={2} />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.coordinates.latitude" />,
            dataIndex: 'coordinatesLatitude',
            key: 'coordinatesLatitude',
            width: 50,
            align: 'right',
            sorter: true,
            render: (value: number | undefined, trapImage: TrapImage) =>
                trapImage.coordinates && (
                    <Link to={`/admin/trap-images/${trapImage.id}`}>
                        <FormattedNumber value={trapImage.coordinates.latitude} minimumFractionDigits={4} maximumFractionDigits={4} />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.coordinates.longitude" />,
            dataIndex: 'coordinatesLongitude',
            key: 'coordinatesLongitude',
            width: 50,
            align: 'right',
            sorter: true,
            render: (value: string | undefined, trapImage: TrapImage) =>
                trapImage.coordinates && (
                    <Link to={`/admin/trap-images/${trapImage.id}`}>
                        <FormattedNumber value={trapImage.coordinates.longitude} minimumFractionDigits={4} maximumFractionDigits={4} />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="trapImage.crop" />,
            dataIndex: 'crop',
            key: 'crop',
            width: 55,
            render: (value: string | undefined, trapImage: TrapImage) => (
                <Link to={`/admin/trap-images/${trapImage.id}`}>
                    <ConstantLabel value={value} prefix="crop.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.farming" />,
            dataIndex: 'farming',
            key: 'farming',
            width: 60,
            render: (value: string | undefined, trapImage: TrapImage) => (
                <Link to={`/admin/trap-images/${trapImage.id}`}>
                    <ConstantLabel value={value} prefix="farming.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="trapImage.pheromoneRenewed" />,
            dataIndex: 'pheromoneRenewed',
            key: 'pheromoneRenewed',
            width: 65,
            align: 'center',
            render: (value: string | undefined, trapImage: TrapImage) => (
                <Link to={`/admin/trap-images/${trapImage.id}`}>
                    <FormattedDate value={trapImage.pheromoneRenewed as any} year="numeric" month="2-digit" day="2-digit" />
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="trapImages.title" />}
            menu="trapImages"
            path={[{ path: '/admin/trap-images', name: <FormattedMessage id="trapImages.title" /> }]}
        >
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row>
                    <Col span={24} lg={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'trapImages.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={paginationService.createPagination(trapImagesPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
                scroll={{ x: 1950 }}
            />
        </LayoutComponent>
    );
};
export default AdminTrapImagesPage;

interface Filter {
    searchText?: string | undefined;
}
